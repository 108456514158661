
import Vue from "vue";
import Component from "vue-class-component";

import Divider from "@/components/ewa/common/Divider.vue";
import { StoreActionTypes } from "@/store/types";
import { EwaInstance, EwaInstanceQr, EwaTariff } from "@/services/types/ewa";

const ChannelCreateModalProps = Vue.extend({
  props: {
    type: {
      type: String,
      default: "create",
    },
    idInstance: {
      type: Number,
    },
  },
});
@Component({
  components: {
    Divider,
  },
})
export default class EwaChannelCreateModal extends ChannelCreateModalProps {
  async mounted() {
    if (this.type == "refresh") {
      await this.refreshQR();
    }
    await this.getQr();
  }

  get currentQr() {
    return this.$store.getters.getCurrentQr
      ? this.$store.getters.getCurrentQr.imageQrCode
      : null;
  }

  async getQr() {
    await this.$store.dispatch(StoreActionTypes.GET_QR, this.idInstance);
  }

  async refreshQR() {
    await this.$store.dispatch(
      StoreActionTypes.LOGOUT_INSTANCE,
      this.idInstance
    );
  }

  get isLoading() {
    return this.$store.getters.ewaDialogIsLoading;
  }

  private instructionSteps = [
    "Откройте <b>WhatsApp</b> с номером, который вы хотите привязать к нашему сервису",
    "Нажмите меню «⁝» или «Настройки» → «Связанные устройства» → «Привязка устройства»",
    "Наведите телефон на экран, чтобы считать код. Код действует 1 минуту — после нажмите «Сгенерировать новый».",
  ];

  async close() {
    await this.$store.dispatch(StoreActionTypes.GET_USER_INSTANCES);
    (this.$root as any).closeDialog();
  }
}
